import React from 'react'
import myResume from '../../Assets/TalhaAmjad.pdf'

const Buttons = () => {
  function openPdf(event) {
    // Specify the path to your PDF file in the assets folder
    // const pdfPath = 'C:/Users/zamja/Desktop/Projects/MyPortfolio/src/Assets/Talha_Resume.pdf';
    
    // Prevent the default behavior of the link
    event.preventDefault();

    // Open the PDF file in a new tab
    // Adding comment
    window.open(myResume, '_blank');
  }

  return (
    <div className='actionButtons'>
      <a href="" className='btn' onClick={openPdf}>Download CV</a>
      <a href='https://www.linkedin.com/in/talha-amjad01/' className='btn btn-primary'>Contact</a>
    </div>
  );
};

export default Buttons