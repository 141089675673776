import React from 'react'
import {BsLinkedin, BsGithub, BsInstagram} from 'react-icons/bs'

const Socials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/talha-amjad01/' target='_blank'><BsLinkedin/></a>
        <a href='https://github.com/Talha2000' target='_blank'><BsGithub/></a>
        <a href='https://www.instagram.com/talha_amjad19/' target='_blank'><BsInstagram/></a>
    </div>
  )
}

export default Socials