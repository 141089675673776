import React from 'react'
import './Header.css'
import mcmaster4 from '../../Assets/mcmaster4.jpg'
import profile from '../../Assets/profile.jpg'

import Buttons from './Buttons'
import Socials from './Socials'

const Header = () => {
  return (
    <header>
      <div className='container header__container'>
        <h5>Hello I'm</h5>
        <h1>Talha Amjad</h1>
        <h5 className='text-light'>Software Developer</h5>
        <Buttons />
        <Socials />

        {/* Replace this image with something else */}
        {/* <div className="myImage">
          <img src={profile} alt='' />
        </div> */}

        <a href='#Contact' className='scroll__down'>Scroll Down</a>

      </div>

    </header>
  )
}

export default Header