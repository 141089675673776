import React from 'react'
import './Portfolio.css';
import mcmaster4 from '../../Assets/mcmaster4.jpg'
import stockPic from '../../Assets/stockPic.png'
import capstonePic from '../../Assets/capstonePic.png'
import ecomscribePic from '../../Assets/ecomscribePic.png'

const data = [
  {
    id: 1,
    image: ecomscribePic,
    title: 'SaaS Ecommerce Platform',
    github: null,
    demo: 'https://ecomscribe.vercel.app/'
  },
  {
    id: 2,
    image: capstonePic,
    title: 'Capstone: Journey AI',
    github: null,
    demo: "http://143.198.34.238/"
  },
  {
    id: 3,
    image: stockPic,
    title: 'Stock Tracker',
    github: 'https://github.com/Talha2000/StockTracker',
    demo: "https://mystocktracker.vercel.app"
  },
  {
    id: 4,
    image: null,
    title: 'Expense Tracker',
    github: 'https://github.com/Talha2000/ExpenseTracker2',
    demo: null
  },
  {
    id: 5,
    image: null,
    title: 'Image Modifier Application',
    github: 'https://github.com/Talha2000/ImageModifier.git',
    demo: null
  },
  {
    id: 6,
    image: null,
    title: 'Budget Calculator',
    github: 'https://github.com/Talha2000/BudgetCalculator',
    demo: null
  },
  {
    id: 7,
    image: null,
    title: 'Product Catalogue Service (WIP)',
    github: null,
    demo: null
  },
]


const Portfolio = () => {
  return (
    <section id='Portfolio'>
      <h2>My Portfolio</h2>
      <div className='container portfolio_container'>
      {
        data.map(({id, image, title, demo, github}) => {
          return (
            <article key={id} className='portfolio_content'>
            <div className="portfolio_item-image">
            {image && <img src={image} alt={title} />}
            </div>
            <h3>{title}</h3>
            <div className="portfolio_buttons">
            {github && (
              <a href={github} className='btn'>GitHub</a>
            )}
            {demo && <a href={demo} className='btn btn-primary'>Live Demo</a>}
            </div>
          </article>
          )
        })
      } 
      </div>
    </section>
  )
}

export default Portfolio