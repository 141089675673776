import React from 'react';
import './Experience.css';
import {BsPatchCheckFill} from 'react-icons/bs'
const Experience = () => {
  return (
    <section id='Experience'>
      {/* <h5>My Skills</h5> */}
      <h2>My Skills</h2>
      <div className='container experience_container'>
        <div className='front_end'>
          {/* <h3>Frontend</h3> */}
          <div className="experience_content">
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>HTML</h4>
              <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>CSS</h4>
              <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>Angular</h4>
              <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>React</h4>
              <small className='text-light'>Intermediate</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>Next.js</h4>
              <small className='text-light'>Intermediate</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>TypeScript</h4>
              <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='details'>
            <BsPatchCheckFill className='icon' />
            <div>
              <h4>JavaScript</h4>
              <small className='text-light'>Intermediate</small>
            </div>
            </article>
          </div>
        </div>

        
        <div className='back_end'>
          {/* <h3>Backend</h3> */}
            <div className="experience_content">
              <article className='details'>
              <BsPatchCheckFill className='icon' />
              <div>
                <h4>C#</h4>
                <small className='text-light'>Experienced</small>
              </div>
              </article>
              <article className='details'>
              <BsPatchCheckFill className='icon' />
              <div>
                <h4>.Net</h4>
                <small className='text-light'>Experienced</small> 
              </div>
              </article>
              <article className='details'>
              <BsPatchCheckFill className='icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Intermediate</small>
              </div>
              </article>
              <article className='details'>
              <BsPatchCheckFill className='icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
              </article>
              <article className='details'>
              < BsPatchCheckFill className='icon' />
              <div>
                <h4>Oracle SQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
              </article>
              <article className='details'>
              < BsPatchCheckFill className='icon' />
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>Experienced</small>
              </div>
              </article>
              <article className='details'>
              < BsPatchCheckFill className='icon' />
              <div>
                <h4>Flask</h4>
                <small className='text-light'>Beginner</small>
              </div>
              </article>
              <article className='details'>
              < BsPatchCheckFill className='icon' />
              <div>
                <h4>Node.js</h4>
                <small className='text-light'>Experienced</small>
              </div>
              </article>
            </div>
        </div>
        {/* <div className='Certificates'>
        </div> */}
      </div>
    </section>
  )
}

export default Experience